.container{
    display: flex;
    gap: 30px;
    position: sticky;
    float: left;
}
.tabs{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 300px;
    height: 100vh;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    
}

input[type='file']::file-selector-button{
    display: none;
    align-items: center;
}
input[type='file']{
    padding: 17px 15px;
}
.phone-number{
    background-color: red;
}
.containerClass{
    background-color: red;
}
.MuiTabs-root{
    /* background-color: red; */
    border-radius: 2px;
    &:focus{
        border: none;
    }
    &:first-child{
        color: lime;
    }
}
.MuiTabs-flexContainer{
    &:active{
    /* background-color: yellow; */

    }
}
.MuiTab-root{
    background-color: red;
}

.horizon path{
    fill: #2C2669;
    padding: 5px;
    cursor: pointer;
    background-color: #2C2669;
}
.horizon :hover{
    fill: white;
    color: white;
    opacity: 1;
}
.vertical path{
    /* fill: #2C2669; */
    padding: 5px;
    cursor: pointer;
}
/* .vertical:active{
    fill: white;
    color: white;
    opacity: 1;
} */

/* reset icon */
.reset-icon {
    display: inline-block;
    font-size: 2rem;
    transition: transform 1s;
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.section-scroll::-webkit-scrollbar{
    display: none;
}
.section-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

option{
    font-size: 20px;
    color: #6053B9;
    cursor: pointer;
}
option:hover{
    background-color: inherit;

}
.selected-option {
    opacity: 0.7;
    color: red;
    cursor: pointer;
  }