.MuiSelect-root,
.MuiSelect-nativeInput {
  background-color: inherit;
  border: 1px solid rgb(240, 58, 58);
  outline: none; /* Remove the default outline on focus */
}

.MuiSelect-icon{
    background-color: inherit;
    border: none;
    appearance: none;
    /* border: 1px solid red; */
    display: none;
    appearance: none;
}
.phone-number{
  margin-top: 10px;
}
/* datepicker.css */
.react-datepicker {
  background-color: red;
  /* width: 217px; */
  /* height: 300px; */
  border-radius: 10px;
  border: none;
  background: var(--Color-7, #FFF);
  box-shadow: 0px 0px 10px 2px rgba(66, 84, 102, 0.20);
}

.react-datepicker__input-container input {
  /* background-color: lime; */
  
}

.react-datepicker__month {
  /* background-color: rgb(218, 69, 35); */
  border-radius: 4px;
  /* background: var(--100, #f8eef7); */
  display: flex;
  /* width: 57px; */
  /* height: 80px; */
  padding: 8px 19px 8px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

}

